import Navbar from "../../Components/Navbar/Navbar"
import Scripts from "../../Apps/Scripts/Scripts"
import Footer from "../../Components/Footer/Footer"

const ScriptsPage = () => {
    return(
        <>
        <Navbar></Navbar>
        <Scripts></Scripts>
        <Footer></Footer>
        </>
    )
}

export default ScriptsPage