import Navbar from "../../Components/Navbar/Navbar"
import Mappings from "../../Apps/Mappings/Mappings"
import Footer from "../../Components/Footer/Footer"

const MappingsPage = () => {
    return(
        <>
        <Navbar></Navbar>
        <Mappings></Mappings>
        <Footer></Footer>
        </>
    )
}

export default MappingsPage