import React from 'react';
import './App.css';

import { BrowserRouter as Router,Routes, Route, Navigate } from 'react-router-dom';

import Home from './Pages/Home/Home';
// import About from './Pages/About/About';
import ScriptsPage from './Pages/Scripts/Scripts';
import MappingsPage from './Pages/MappingsPage/MappingsPage';
import Add from './Pages/Add/Add';
import AddSuccess from './Pages/AddSuccess/AddSuccess';

function App() {
    // disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());
 
    document.onkeydown = function (e) {
 
        // disable F12 key
        if(e.keyCode === 123) {
            return false;
        }
 
        // disable I key
        if(e.ctrlKey && e.shiftKey && e.keyCode === 73){
            return false;
        }
 
        // disable J key
        if(e.ctrlKey && e.shiftKey && e.keyCode === 74) {
            return false;
        }
 
        // disable U key
        if(e.ctrlKey && e.keyCode === 85) {
            return false;
        }
    }
  return (
    <>
    <Router>
     <Routes>
       <Route  path='/' element={<Home></Home>}></Route>
       {/* <Route  path='/about' element={<About></About>}></Route> */}
       <Route  path='/resources/scripts' element={<ScriptsPage></ScriptsPage>}></Route>
       <Route  path='/resources/mappings' element={<MappingsPage></MappingsPage>}></Route>
       <Route  path='/resources/add' element={<Add></Add>}></Route>
       <Route  path='/resources/add/success' element={<AddSuccess></AddSuccess>}></Route>
       <Route  path="*" element={<Navigate to="/" />} />
     </Routes>
   </Router>
   </>
  );
}

export default App;
