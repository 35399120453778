import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from "../../Assets/logo.png"

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/">
          <img className="logo" src={logo} alt="logo"></img>
        </Link>
        <ul className="nav-links">
          <li>
            <Link className="nav-link" to="/">Accueil</Link>
          </li>
          <li>
            <Link className="nav-link" to="/resources/scripts">Répertoire de Scripts</Link>
          </li>
          <li>
            <Link className="nav-link" to="">Répertoire de Mappings (Bientôt Disponible)</Link>
          </li>
          <li className='bouton'>
            <Link className="nav-link" to="/resources/add">Ajouter</Link>
          </li>
          <li className='bouton2'>
            <a className="nav-link" href="https://discord.gg/family-developpement-687326967661330538" target="_blank" rel="noopener noreferrer">Rejoindre Le Discord !</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
