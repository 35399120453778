import React from 'react';

import { Link } from 'react-router-dom';

import './Home.css'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import Check from "../../Assets/check.svg";
import showcaseimg from "../../Assets/dash.png";

const Accueil = () => {
  return (
    <>
    <Navbar></Navbar>
    <div className='home-div'>
      <div className='home-div-1'>
        <div className='home-div-1-block-1'>
          <div className='home-div-1-block-1-h'>
          <h1>Télécharge <span className='yellow'>gratuitement</span> des ressources FiveM.</h1>
          </div>
        </div>
        <div className='desc'>
          <p>Le premier répertoire de script gratuit, alimenté par la communauté !</p>
        </div>
        <div className='cta-block'>
        <Link className="nav-link" to="/resources/scripts">
        <div className='cta-block-bouton'>
        👉 Utiliser Gratuitement
        </div>
        </Link>
          <div className='cta-block-arguments'>
            <div className='cta-block-arguments-1'>
              <img src={Check} alt="check"></img>
              Totalement gratuit
            </div>
            <div className='cta-block-arguments-1'>
              <img src={Check} alt="check"></img>
              Accessible sans compte
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='showcase-space-b1'>
        <div className='showcase-space-b2'>
          <img src={showcaseimg} alt="showcaseimage"></img>
        </div>
    </div>
    <div className='home-div-trusted'>
      <h1>✅ +200 SCRIPTS PARTAGÉS ET VÉRIFIÉS PAR NOTRE ÉQUIPE</h1>
    </div>
    <div className='home-div-cta-post'>
      <div className='home-div-cta-post-block'>
        <h1>Tu veux partager ?</h1>
        <h2>Rend les scripts disponibles à un large éventail d'utilisateurs</h2>
        <Link className="nav-link" to="/resources/add">
        <button>Ajouter →</button>
        </Link>
      </div>
    <div className='home-div-cta-discord'>
      <div className='home-div-cta-discord-1'>
        <div className='home-div-cta-discord-1-text'>
          <h1>Rejoins notre communauté !</h1>
          <p>Dédié à l'entraide et au développement, principalement sur FiveM et au partage de script en tout genre.</p>
        </div>
      </div>
      <a href="https://discord.gg/family-developpement-687326967661330538" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <button>Discord</button>
      </a>
    </div>
    </div>

    <Footer></Footer>
    </>
  );
};

export default Accueil;
