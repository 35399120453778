import './Footer.css'
import { Link } from 'react-router-dom';

import logo from '../../Assets/logo.png';
import discord from '../../Assets/discord.svg';

const Footer = () => {
    return(
        <>
    <footer>
        <div className='footer-1'>
            <div className='footer-1-text'>
                <img src={logo} alt="logo"></img>
                <h1>© 2023 Family Dev. All Rights Reserved.</h1>
            </div>
        </div>
    </footer>
        </>
    )
}

export default Footer