import React from 'react';

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import './AddSuccess.css'

const Succes = () => {
  return (
    <>
    <Navbar></Navbar>
    <div className="succes">
      <h1>Merci ! Votre script a été soumis avec succès.</h1>
      <p>
        Votre script a été ajouté à notre répertoire avec succès. Cependant, avant qu'il ne soit publié publiquement,
        notre équipe effectuera une vérification pour s'assurer qu'il répond à nos critères de qualité et de sécurité.
      </p>
      <p>
        Nous apprécions votre contribution à notre communauté de développeurs. Veuillez patienter pendant que nous
        examinons votre script. Une fois la vérification terminée, votre script sera visible dans notre répertoire.
      </p>
      <p>
        Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter directemement sur notre serveur Discord.
      </p>
      <p>Merci encore pour votre contribution !</p>
    </div>
    <Footer></Footer>
    </>
  );
};

export default Succes;
