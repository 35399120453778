import React, { useState } from 'react';
import "./Scripts.css"
import script from "../../Data/scriptsData.json"

const Scripts = () => {
  const [scriptsData] = useState(script);
  const [searchText, setSearchText] = useState('');

  // Filtrer les scripts en fonction de la recherche
  const filteredScripts = scriptsData.filter(scriptData =>
    scriptData.title.toLowerCase().includes(searchText.toLowerCase()) ||
    scriptData.description.toLowerCase().includes(searchText.toLowerCase()) ||
    scriptData.auteur.toLowerCase().includes(searchText.toLowerCase())
  );
  
  // Fonction pour créer les éléments HTML pour chaque script
  function createScriptElement(scriptData) {
    const handleButtonClick = () => {
      // Replace 'WEBHOOK_URL_HERE' with your actual webhook URL
      const webhookUrl = 'https://discord.com/api/webhooks/1161027744721801327/awi29djCyT_55ev1mTDCBl6ikXc8LxRM7xG1WedeQPcfc4N7YS4EQwXIdmZI4VbXYSdk';

      // Create the message payload
      const messagePayload = {
        content: `+1 | Script Download | ${scriptData.title}`,
      };

      // Make the POST request to the webhook URL
      fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messagePayload),
      });
    };

    return (
      <article key={scriptData.title} className="information">
        <a href={scriptData.video} target="_blank" rel="noopener noreferrer"><img src={scriptData.img} className='img' alt="script"></img></a>
        <h2 className="title">{scriptData.title}</h2>
        <p className="info">{scriptData.description}</p>
        <p className="infoz">Créer par {scriptData.auteur}.</p>
        <a href={scriptData.download} target="_blank" rel="noopener noreferrer"><button className="btn" onClick={handleButtonClick}>
          <i className="fa fa-download"></i> Download
        </button>
        </a>
      </article>
    );
  }

  return (
    <div className='background'>
      <header>
        <h1>Répertoire de Scripts</h1>
        <input
          type="text"
          id="searchInput"
          placeholder="Rechercher un script..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </header>
      <div className="script-container">
        {filteredScripts.length === 0 ? (
          <p>😭 Aucun Résultat pour "{searchText}"</p>
        ) : (
          filteredScripts.map(scriptData => createScriptElement(scriptData))
        )}
      </div>
    </div>
  );
}

export default Scripts;
