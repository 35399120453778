import React, { useState } from 'react';
// import "./Mappings.css"
import mapping from "../../Data/mappingsData.json"

const Mappings = () => {
  const [mappingsData] = useState(mapping);
  const [searchText, setSearchText] = useState('');

  // Filtrer les mappings en fonction de la recherche
  const filteredmappings = mappingsData.filter(mappingData =>
    mappingData.title.toLowerCase().includes(searchText.toLowerCase()) ||
    mappingData.demappingion.toLowerCase().includes(searchText.toLowerCase()) ||
    mappingData.auteur.toLowerCase().includes(searchText.toLowerCase())
  );
  
  // Fonction pour créer les éléments HTML pour chaque mapping
  function createmappingElement(mappingData) {
    const handleButtonClick = () => {
      // Replace 'WEBHOOK_URL_HERE' with your actual webhook URL
      const webhookUrl = 'https://discord.com/api/webhooks/1134133421275369584/d8EkFtj-0ugL41ZY0yAu_jLc_ZS9dbNNP9FqxriQ670obpG5g030zcD2Trf-XB3dfBZI';

      // Create the message payload
      const messagePayload = {
        content: `+1 | Download | "${mappingData.title}" | ${mappingData.download}`,
      };

      // Make the POST request to the webhook URL
      fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messagePayload),
      });
    };

    return (
      <article key={mappingData.title} className="information" rel="noopener noreferrer">
        <a href={mappingData.video} target="_blank" rel="noopener noreferrer"><img src={mappingData.img} className='img' alt="mapping"></img></a>
        <h2 className="title">{mappingData.title}</h2>
        <p className="info">{mappingData.demappingion}</p>
        <p className="infoz">Créer par {mappingData.auteur}.</p>
        <button className="btn" onClick={handleButtonClick}>
          <i className="fa fa-download"></i> Download
        </button>
      </article>
    );
  }

  return (
    <div>
      <header>
        <h1>Répertoire de Mappings</h1>
        <input
          type="text"
          id="searchInput"
          placeholder="Rechercher un mapping..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </header>
      <div className="mapping-container">
        {filteredmappings.length === 0 ? (
          <p>😭 Aucun Résultat pour "{searchText}"</p>
        ) : (
          filteredmappings.map(mappingData => createmappingElement(mappingData))
        )}
      </div>
    </div>
  );
}

export default Mappings;
