import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import './Add.css';

const Add = () => {
  const [formData, setFormData] = useState({
    scriptName: '',
    description: '',
    downloadLink: '',
    author: '',
    discordId: '', // Nouvel état pour l'ID Discord
    image: null,
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (Object.values(formData).some((value) => !value)) {
      alert('Veuillez remplir tous les champs du formulaire.');
      return;
    }
  
    // Envoi du premier message
    const scriptPayload = {
      content: `Name: ${formData.scriptName}\nDescription: ${formData.description}\nDownloadLink: ${formData.downloadLink}\nAuthor: ${formData.author}\nDiscord ID: ${formData.discordId}`,
    };
  
    const scriptFormData = new FormData();
    scriptFormData.append('payload_json', JSON.stringify(scriptPayload));
  
    if (formData.image) {
      scriptFormData.append('image', formData.image);
    }
  
    try {
      const webhookUrl = 'https://discord.com/api/webhooks/1161027744721801327/awi29djCyT_55ev1mTDCBl6ikXc8LxRM7xG1WedeQPcfc4N7YS4EQwXIdmZI4VbXYSdk';
      await axios.post(webhookUrl, scriptFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Envoi du deuxième message (ajoutez cette partie)
      const secondMessagePayload = {
        content: '+1 Script Added',
      };
  
      const secondMessageFormData = new FormData();
      secondMessageFormData.append('payload_json', JSON.stringify(secondMessagePayload));
  
      const secondMessageWebhookUrl = 'https://discord.com/api/webhooks/1161027539142201344/IjdA0GJzHjYCoZ4bM5oGU9SZdtgp3OBVJuYKGKLrjYIPDOWDsSR9s7uisg9obdL_HnQG';
      await axios.post(secondMessageWebhookUrl, secondMessageFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setFormData({
        scriptName: '',
        description: '',
        downloadLink: '',
        author: '',
        discordId: '',
        image: null,
      });
      setSubmitted(true);
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire :', error);
      alert('Une erreur s\'est produite lors de la soumission du script.');
    }
  };  

  if (submitted) {
    return <Navigate to="/resources/add/success" />;
  }

  return (
    <>
      <Navbar />
      <div className='add-div'>
        <div className="form-container">
          <h2>Ajouter un script</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Nom du script:</label>
              <input
                type="text"
                name="scriptName"
                value={formData.scriptName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Description:</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Lien de téléchargement:</label>
              <input
                type="text"
                name="downloadLink"
                value={formData.downloadLink}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Auteur:</label>
              <input
                type="text"
                name="author"
                value={formData.author}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Votre ID Discord:</label>
              <input
                type="text"
                name="discordId"
                value={formData.discordId}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Image du script:</label>
              <input type="file" onChange={handleImageChange} />
            </div>
            <div>
              <button type="submit">Soumettre</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Add;
